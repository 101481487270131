import React, { useState, useEffect } from "react";
import { ethers, BigNumber } from "ethers";
import contractAbi from "./ABI/contractAbi.json";
import tokenAbi from "./ABI/tokenAbi.json";
import ConnectButton from "./walletConnection/ConnectButton";
import axios from "axios";
import "./App.css";

const getContract = () => {
  const contractAddress = "0x5e421Fe3D2066Af572bc2F04839729869b6354a9"; //process.env.REACT_APP_CONTRACT_ADDRESS;
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contractAddress, contractAbi, signer);

  return contract;
};

const App = () => {
  const [totalPayout, setTotalPayout] = useState(0);
  const [tokenCount, setTokenCount] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [txs, setTxs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchWalletQuery, setSearchWalletQuery] = useState("");
  const [editEmployeeIndex, setEditEmployeeIndex] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showTxsModal, setShowTxsModal] = useState(false);
  const [editWallet_address, setEditWallet_address] = useState("");
  const [editSalary, setEditSalary] = useState("");
  const [editBonus, setEditBonus] = useState(0);
  const [editPenalty, setEditPenalty] = useState(0);
  const [newEmployee, setNewEmployee] = useState({
    full_name: "",
    wallet_address: "",
    salary: "",
  });
  const [fieldFocused, setFieldFocused] = useState({
    full_name: false,
    wallet_address: false,
    salary: false,
  });

  useEffect(() => {
    fetchEmployees();
    checkBalance();
  }, []);

  useEffect(() => {
    calculateTotalPayout();
  }, [employees]);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "employees"
      );
      setEmployees(response.data);
    } catch (error) {
      console.error("Ошибка отображения сотрудников: ", error);
      alert("Ошибка отображения сотрудников");
    }
  };

  const checkBalance = async () => {
    try {
      const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
      const provider = new ethers.providers.JsonRpcProvider(
        `${process.env.REACT_APP_BSC_MAINNET}`
      );

      const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
      const tokenContract = new ethers.Contract(
        tokenAddress,
        tokenAbi,
        provider
      );

      const contractTokenBalance = (
        await tokenContract.balanceOf(contractAddress)
      ).toString();
      const frontendBalance = contractTokenBalance / 10 ** 18;
      setTokenCount(frontendBalance);
    } catch (error) {
      console.error("Ошибка отображения баланса смарт контракта: ", error);
      alert("Ошибка отображения баланса смарт контракта");
    }
  };

  const createEmployee = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setShowEditModal(false);
    setNewEmployee({
      full_name: "",
      wallet_address: "",
      salary: "",
    });
    setFieldFocused({
      full_name: false,
      wallet_address: false,
      salary: false,
    });
    setError("");
  };

  const handleTxsModalClose = () => {
    setShowTxsModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEmployee({
      ...newEmployee,
      [name]: value,
    });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "editSalary") {
      if (!isNaN(Number(value))) {
        setError("");
        setEditSalary(value);
      } else {
        setError("Оклад должен быть числом");
      }
    } else if (name === "editBonus") {
      if (!isNaN(Number(value))) {
        setError("");
        setEditBonus(value);
      } else {
        setError("Бонус должен быть числом");
      }
    } else if (name === "editPenalty") {
      if (!isNaN(Number(value))) {
        setError("");
        setEditPenalty(value);
      } else {
        setError("Штраф должен быть числом");
      }
    }
  };

  const handleFieldFocus = (fieldName) => {
    setFieldFocused({
      ...fieldFocused,
      [fieldName]: true,
    });
  };

  const handleFieldBlur = (fieldName) => {
    if (newEmployee[fieldName] === "") {
      setFieldFocused({
        ...fieldFocused,
        [fieldName]: false,
      });
    }
  };

  const validateForm = () => {
    if (newEmployee.full_name === "") {
      setError("Введите ФИО сотрудника");
      return false;
    }
    if (
      employees.some((employee) => employee.full_name === newEmployee.full_name)
    ) {
      setError("Такой сотрудник уже работает");
      return false;
    }
    if (newEmployee.wallet_address === "") {
      setError("Введите адрес кошелька сотрудника");
      return false;
    }
    if (
      !newEmployee.wallet_address.startsWith("0x") ||
      newEmployee.wallet_address.length !== 42
    ) {
      setError(
        'Адрес кошелька должен начинаться с "0x" и иметь длину 42 символа'
      );
      return false;
    }
    if (newEmployee.salary === "") {
      setError("Введите оклад сотрудника");
      return false;
    }
    if (isNaN(Number(newEmployee.salary))) {
      setError("Оклад должен быть числом");
      return false;
    }
    return true;
  };

  const addEmployee = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let receipt;

      try {
        const contract = getContract();

        const tx = await contract.addEmployee(
          newEmployee.wallet_address,
          BigNumber.from(newEmployee.salary).mul(
            BigNumber.from("1000000000000000000")
          ),
          {
            gasLimit: 2000000,
          }
        );
        receipt = await tx.wait();
      } catch (error) {
        alert("Ограничение в смарт контракте");
        console.error(
          "Не удалось добавить сотрудника в смарт контракт: ",
          error
        );
      }

      if (receipt.status === 1) {
        try {
          await axios.post(process.env.REACT_APP_API_URL + "employees", {
            full_name: newEmployee.full_name,
            wallet_address: newEmployee.wallet_address,
            salary: newEmployee.salary,
          });
          fetchEmployees();
          handleModalClose();
        } catch (error) {
          alert("Ограничение в базе данных");
          console.error(
            "Не удалось добавить сотрудника в базу данных: ",
            error
          );
        }
      } else {
        console.log("Ошибка при выполнении транзакции на смарт-контракте");
      }
    }
  };

  const openEditModal = (wallet_address) => {
    const employee = employees.find(
      (emp) => emp.wallet_address === wallet_address
    );
    setEditSalary(employee.salary);
    setEditBonus(employee.bonus);
    setEditPenalty(employee.penalty);
    setEditWallet_address(wallet_address);
    setShowEditModal(true);
  };

  const saveChanges = async () => {
    try {
      const contract = getContract();

      const employeeNumber = await contract.checkEmployeeNumber(
        editWallet_address
      );

      if (editSalary !== 0) {
        const salaryData = {
          wallet_address: editWallet_address,
          salary: editSalary,
        };
        let receiptSalary;

        try {
          const txSalary = await contract.setEmployeeSalary(
            employeeNumber,
            BigNumber.from(editSalary).mul(
              BigNumber.from("1000000000000000000")
            ),
            {
              gasLimit: 2000000,
            }
          );
          receiptSalary = await txSalary.wait();
        } catch (error) {
          alert("Ограничение в смарт контракте (оклад)");
          console.error("Не удалось изменить оклад в смарт контракте: ", error);
        }

        if (receiptSalary.status === 1) {
          try {
            await axios.put(
              process.env.REACT_APP_API_URL + "employees/salary",
              salaryData
            );
            console.log("Оклад поменяли");
          } catch (error) {
            alert("Ограничение в базе данных (оклад)");
            console.error("Не удалось изменить оклад в базе данных: ", error);
          }
        } else {
          console.log("Ошибка при выполнении транзакции на смарт-контракте");
        }
      }

      if (editBonus !== 0) {
        const bonusData = {
          wallet_address: editWallet_address,
          bonus: editBonus,
        };
        let receiptBonus;

        try {
          const txBonus = await contract.setEmployeeBonus(
            employeeNumber,
            BigNumber.from(editBonus).mul(
              BigNumber.from("1000000000000000000")
            ),
            {
              gasLimit: 2000000,
            }
          );
          receiptBonus = await txBonus.wait();
        } catch (error) {
          alert("Ограничение в смарт контракте (премия)");
          console.error(
            "Не удалось изменить премию в смарт контракте: ",
            error
          );
        }

        if (receiptBonus.status === 1) {
          try {
            await axios.put(
              process.env.REACT_APP_API_URL + "employees/bonus",
              bonusData
            );
            console.log("Премию поменяли");
          } catch (error) {
            alert("Ограничение в базе данных (премия)");
            console.error("Не удалось изменить премию в базе данных: ", error);
          }
        } else {
          console.log("Ошибка при выполнении транзакции на смарт-контракте");
        }
      }

      if (editPenalty !== 0) {
        const penaltyData = {
          wallet_address: editWallet_address,
          penalty: editPenalty,
        };
        let receiptPenalty;

        try {
          const txPenalty = await contract.setEmployeePenalty(
            employeeNumber,
            BigNumber.from(editPenalty).mul(
              BigNumber.from("1000000000000000000")
            ),
            {
              gasLimit: 2000000,
            }
          );
          receiptPenalty = await txPenalty.wait();
        } catch (error) {
          alert("Ограничение в смарт контракте (штраф)");
          console.error("Не удалось изменить штраф в смарт контракте: ", error);
        }

        if (receiptPenalty.status === 1) {
          try {
            await axios.put(
              process.env.REACT_APP_API_URL + "employees/penalty",
              penaltyData
            );
            console.log("Премию поменяли");
          } catch (error) {
            alert("Ограничение в базе данных (премия)");
            console.error("Не удалось изменить премию в базе данных: ", error);
          }
        } else {
          console.log("Ошибка при выполнении транзакции на смарт-контракте");
        }
      }

      fetchEmployees();
    } catch (error) {
      console.error("Редактирование не завершено: ", error);
      fetchEmployees();
      alert("Редактирование не завершено");
    }

    const editedEmployee = {
      ...employees[editEmployeeIndex],
      salary: editSalary,
      bonus: editBonus,
      penalty: editPenalty,
      totalPayout:
        parseInt(editSalary) + parseInt(editBonus) - parseInt(editPenalty),
    };
    const updatedEmployees = [...employees];
    updatedEmployees[editEmployeeIndex] = editedEmployee;
    setEmployees(updatedEmployees);
    handleModalClose();
  };

  const deleteTheEmployee = async () => {
    const data = {
      wallet_address: editWallet_address,
    };

    let receipt;

    try {
      const contract = getContract();

      const employeeNumber = await contract.checkEmployeeNumber(
        editWallet_address.toString()
      );
      console.log(`Номер сотрудника: ${employeeNumber}`);
      const tx = await contract.deleteEmployee(employeeNumber, {
        gasLimit: 2000000,
      });
      receipt = await tx.wait();
    } catch (error) {
      alert("Ограничение в смарт контракте");
      console.error(
        "Не удалось удалить сотрудника из смарт контракта: ",
        error
      );
    }

    if (receipt.status === 1) {
      try {
        await axios.delete(
          process.env.REACT_APP_API_URL + "employees/" + editWallet_address,
          data
        );
        fetchEmployees();
      } catch (error) {
        alert("Ограничение в базе данных");
        console.error("Не удалось удалить сотрудника из базы данных: ", error);
      }
    } else {
      console.log("Ошибка при выполнении транзакции на смарт-контракте");
    }

    setShowEditModal(false);
    handleModalClose();
  };

  const checkAllTxs = async () => {
    setShowTxsModal(true);
    renderTableTxsRows();
  };

  const paySalaries = async () => {
    let receipt;
    try {
      const contract = getContract();

      const tx = await contract.paySalary({
        gasLimit: 2000000,
      });
      receipt = await tx.wait();
    } catch (error) {
      alert("Ограничение в смарт контракте");
      console.error("Не удалось выплатить зарплаты в смарт контракте: ", error);
    }

    if (receipt.status === 1) {
      try {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
        const day = ("0" + currentDate.getDate()).slice(-2);

        const formattedDate = `${year}-${month}-${day}`;
        const amount = calculateTotalPayout();
        const transactionHash = receipt.transactionHash;
        const explorerLink = `https://bscscan.com/tx/${transactionHash}`;

        const transactionData = {
          date: formattedDate,
          amount: amount,
          hash: explorerLink,
        };

        await axios.post(
          process.env.REACT_APP_API_URL + "transactions",
          transactionData
        );

        for (let employee of employees) {
          const data = {
            wallet_address: employee.wallet_address,
            salary: employee.salary,
            bonus: 0,
            penalty: 0,
          };
          await axios.put(process.env.REACT_APP_API_URL + "employees", data);
        }
        alert("Зарплаты успешно выплачены");
        fetchEmployees();
      } catch (error) {
        alert("Ограничение в базе данных");
        console.error(
          "Не удалось обнулить Премии и Штрафы сотрудников или добавить транзакцию в базу данных: ",
          error
        );
      }
    } else {
      console.log("Ошибка при выполнении транзакции на смарт-контракте");
    }
  };

  const renderTableRows = () => {
    return employees
      .filter(
        (employee) =>
          employee.full_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) &&
          employee.wallet_address
            .toLowerCase()
            .includes(searchWalletQuery.toLowerCase())
      )
      .map((employee, index) => (
        <tr key={index}>
          <td>
            <span className="lablelMobile">Сотрудник</span>
            <span>{employee.full_name}</span>
          </td>
          <td>
            <span className="lablelMobile">Кошелек</span>
            <span className="smallText">{employee.wallet_address}</span>
          </td>
          <td>
            <span className="lablelMobile">Оклад</span>
            <span>{employee.salary}</span>
          </td>
          <td>
            <span className="lablelMobile">Премия</span>
            <span>{employee.bonus}</span>
          </td>
          <td>
            <span className="lablelMobile">Штрафы</span>
            {employee.penalty}
          </td>
          <td>
            <span className="lablelMobile">Выплата</span>
            {parseInt(employee.salary) +
              parseInt(employee.bonus) -
              parseInt(employee.penalty)}
          </td>
          <td>
            <button
              className="table-button"
              onClick={() => openEditModal(employee.wallet_address)}
            >
              Изменить
            </button>
          </td>
        </tr>
      ));
  };

  const renderTableTxsRows = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "transactions"
      );
      setTxs(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const calculateTotalPayout = () => {
    let total = 0;
    employees.forEach((employee) => {
      total +=
        parseInt(employee.salary) +
        parseInt(employee.bonus) -
        parseInt(employee.penalty);
    });
    setTotalPayout(total);
    return total;
  };

  function getNextDay(dateString) {
    const [year, month, day] = dateString.split("-").map(Number);
    const date = new Date(year, month - 1, day);
    date.setDate(date.getDate() + 1);

    if (date.getMonth() !== month - 1) {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-01`;
    } else {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`;
    }
  }

  return (
    <div className="container">
      <ConnectButton />

      <h1 className="title">Зарплатный проект ИФ EMIVN</h1>

      <button
        className="connect-button"
        onClick={createEmployee}
        style={{ textAlign: "center" }}
      >
        Создать сотрудника
      </button>

      <div className="search-container">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Поиск по ФИО сотрудника"
          className="search-input"
        />
      </div>

      <div className="search-container">
        <input
          type="text"
          value={searchWalletQuery}
          onChange={(e) => setSearchWalletQuery(e.target.value)}
          placeholder="Поиск по кошелькам"
          className="search-input"
        />
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>Сотрудник</th>
            <th>Кошелек</th>
            <th>Оклад</th>
            <th>Премия</th>
            <th>Штрафы</th>
            <th>Выплата</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>{renderTableRows()}</tbody>
      </table>

      <div className="total-payout">
        Главный админ 0x3B4e3270eA25bdf0826AF34cE456854b88DE9b62
      </div>
      <div className="total-payout">
        Админы 0xe38D50b5Ad007F52590C2C47491a3972bbD9289f
        0x10218Dd78a22D6dab1B40f8cD9a946bfB5f4e107
        0x9804Ce5A25ca68047981d5439bcc23eBBF5aDbfA
      </div>
      <div className="total-payout">
        Общая выплата в{" "}
        <a
          href="https://bscscan.com/address/0x6966b2a543E34E69Cf1c7840137FfD2d8a09e834"
          target="_blank"
          rel="noreferrer"
        >
          Emivn
        </a>
        : {totalPayout}
      </div>
      <div className="total-payout">
        Сейчас токенов на{" "}
        <a
          href="https://bscscan.com/address/0x5e421Fe3D2066Af572bc2F04839729869b6354a9"
          target="_blank"
          rel="noreferrer"
        >
          контракте
        </a>
        : {tokenCount}
      </div>

      <div className="total-bottom">
        <button
          className="connect-button"
          onClick={paySalaries}
          style={{ marginRight: "60px", backgroundColor: "green" }}
        >
          Выплатить всем
        </button>

        <button
          className="connect-button"
          onClick={checkAllTxs}
          style={{ marginRight: "60px" }}
        >
          Все выплаты
        </button>
      </div>
      {showTxsModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2 style={{ width: "400px", textAlign: "center" }}>
              Все выплаты сотрудникам
            </h2>
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Дата</th>
                    <th>Сумма</th>
                    <th>Ссылка</th>
                  </tr>
                </thead>
                <tbody>
                  {txs.map((transaction) => (
                    <tr key={transaction._id}>
                      <td>{getNextDay(transaction.date.slice(0, 10))}</td>
                      <td>{transaction.amount}</td>
                      <td>
                        <button
                          className="table-button"
                          style={{ margin: "0" }}
                          onClick={() =>
                            window.open(transaction.hash, "_blank")
                          }
                        >
                          Перейти
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                className="close-modal-button"
                onClick={handleTxsModalClose}
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Добавление сотрудника</h2>
            <div className="employee-form">
              <div className="form-group">
                <input
                  type="text"
                  id="full_name"
                  name="full_name"
                  className={`form-input ${
                    fieldFocused.full_name || newEmployee.full_name !== ""
                      ? "form-input-filled"
                      : ""
                  }`}
                  value={newEmployee.full_name}
                  onChange={handleInputChange}
                  onFocus={() => handleFieldFocus("full_name")}
                  onBlur={() => handleFieldBlur("full_name")}
                />
                <label
                  className={`form-label ${
                    fieldFocused.full_name || newEmployee.full_name !== ""
                      ? "form-label-hidden"
                      : ""
                  }`}
                  htmlFor="full_name"
                >
                  {fieldFocused.full_name || newEmployee.full_name !== ""
                    ? ""
                    : "ФИО"}
                </label>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  id="wallet_address"
                  name="wallet_address"
                  className={`form-input ${
                    fieldFocused.wallet_address ||
                    newEmployee.wallet_address !== ""
                      ? "form-input-filled"
                      : ""
                  }`}
                  value={newEmployee.wallet_address}
                  onChange={handleInputChange}
                  onFocus={() => handleFieldFocus("wallet_address")}
                  onBlur={() => handleFieldBlur("wallet_address")}
                />
                <label
                  className={`form-label ${
                    fieldFocused.wallet_address ||
                    newEmployee.wallet_address !== ""
                      ? "form-label-hidden"
                      : ""
                  }`}
                  htmlFor="wallet_address"
                >
                  {fieldFocused.wallet_address ||
                  newEmployee.wallet_address !== ""
                    ? ""
                    : "Адрес кошелька"}
                </label>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  id="salary"
                  name="salary"
                  className={`form-input ${
                    fieldFocused.salary || newEmployee.salary !== ""
                      ? "form-input-filled"
                      : ""
                  }`}
                  value={newEmployee.salary}
                  onChange={handleInputChange}
                  onFocus={() => handleFieldFocus("salary")}
                  onBlur={() => handleFieldBlur("salary")}
                />
                <label
                  className={`form-label ${
                    fieldFocused.salary || newEmployee.salary !== ""
                      ? "form-label-hidden"
                      : ""
                  }`}
                  htmlFor="salary"
                >
                  {fieldFocused.salary || newEmployee.salary !== ""
                    ? ""
                    : "Оклад"}
                </label>
              </div>

              {error && <p className="error">{error}</p>}
              <div className="button-group">
                <button className="add-employee-button" onClick={addEmployee}>
                  Добавить сотрудника
                </button>
                <button
                  className="close-modal-button"
                  onClick={handleModalClose}
                >
                  Закрыть
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showEditModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2 style={{ textAlign: "center" }}>Редактирование</h2>
            <p>Оставьте 0, если не хотите менять поле</p>
            <div className="employee-form">
              <div className="form-group">
                <label className="label">Оклад</label>
                <input
                  type="text"
                  id="editSalary"
                  name="editSalary"
                  className={`form-input ${
                    fieldFocused.salary || newEmployee.salary !== ""
                      ? "form-input-filled"
                      : ""
                  }`}
                  value={editSalary}
                  onChange={handleEditInputChange}
                  onFocus={() => handleFieldFocus("salary")}
                  onBlur={() => handleFieldBlur("salary")}
                />
                <label
                  className={`form-label ${
                    fieldFocused.salary || newEmployee.salary !== ""
                      ? "form-label-hidden"
                      : ""
                  }`}
                  htmlFor="salary"
                >
                  {fieldFocused.salary || newEmployee.salary !== "" ? "" : ""}
                </label>
              </div>

              <div className="form-group">
                <label>Премия</label>
                <input
                  type="text"
                  id="editBonus"
                  name="editBonus"
                  className={`form-input ${
                    fieldFocused.bonus || newEmployee.bonus !== ""
                      ? "form-input-filled"
                      : ""
                  }`}
                  value={editBonus}
                  onChange={handleEditInputChange}
                  onFocus={() => handleFieldFocus("bonus")}
                  onBlur={() => handleFieldBlur("bonus")}
                />
                <label
                  className={`form-label ${
                    fieldFocused.bonus || newEmployee.bonus !== ""
                      ? "form-label-hidden"
                      : ""
                  }`}
                  htmlFor="bonus"
                >
                  {fieldFocused.bonus || newEmployee.bonus !== ""
                    ? ""
                    : "Премия"}
                </label>
              </div>

              <div className="form-group">
                <label>Штрафы</label>
                <input
                  type="text"
                  id="editPenalty"
                  name="editPenalty"
                  className={`form-input ${
                    fieldFocused.penalty || newEmployee.penalty !== ""
                      ? "form-input-filled"
                      : ""
                  }`}
                  value={editPenalty}
                  onChange={handleEditInputChange}
                  onFocus={() => handleFieldFocus("penalty")}
                  onBlur={() => handleFieldBlur("penalty")}
                />
                <label
                  className={`form-label ${
                    fieldFocused.penalty || newEmployee.penalty !== ""
                      ? "form-label-hidden"
                      : ""
                  }`}
                  htmlFor="penalty"
                >
                  {fieldFocused.penalty || newEmployee.penalty !== ""
                    ? ""
                    : "Штраф"}
                </label>
              </div>

              {error && <p className="error">{error}</p>}
              <div className="button-group">
                <button className="add-employee-button" onClick={saveChanges}>
                  Сохранить изменения
                </button>
                <button
                  className="add-employee-button"
                  onClick={deleteTheEmployee}
                  style={{ backgroundColor: "red" }}
                >
                  Удалить сотрудника
                </button>
              </div>
              <button
                className="close-modal-button"
                onClick={handleModalClose}
                style={{ margin: "auto", marginTop: "10px" }}
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
